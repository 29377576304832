.DateRangePickerInput {
	border-radius: 7px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  font-size: calc(10px + 2vmin);
  z-index: 100;
}

.modal-main {
  position:fixed;
  background-color: rgba(255,255,255,0.8);
  width: 80%;
  height: auto;
  max-height: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 15px;
  padding: 15px;
  overflow-y: scroll;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}