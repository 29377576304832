@import url(https://fonts.googleapis.com/css?family=Kanit&display=swap);
@import url(https://www.w3schools.com/w3css/4/w3.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Kanit';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-size: 100vw;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: yellow;
}

.footer {
  font-size: calc(0px + 2vmin);
  height: 5vh;
  background-color: black;
  color: white;
  vertical-align: middle;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.fade-in {
animation: fadeIn ease 1.8s;
-webkit-animation: fadeIn ease 1.8s;
-moz-animation: fadeIn ease 1.8s;
-o-animation: fadeIn ease 1.8s;
-ms-animation: fadeIn ease 1.8s;
}
@keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}

@-webkit-keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}


@media screen and (min-width: 0px) and (max-width: 420px) {
    .welcome {
		background-image: url(/static/media/doublebed.36e03b68.jpg);
		background-size: 100vw;
		background-position: 50% 100%;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.overview {
		background-image: url(/static/media/sinkiatthani.4e027df6.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.room {
		background-image: url(/static/media/doublebed2.b347f9a4.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.attraction {
		background-image: url(/static/media/twinbed.41906e21.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.booknow {
		background-image: url(/static/media/twinbed.41906e21.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.gallery {
		background-image: url(/static/media/doublebed3.ddf8c79f.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.contact {
		background-image: url(/static/media/twinbed2.d4dbb403.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}
	.booking {
		background-image: url(/static/media/twinbed3.47d0fb8a.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 95vh;
		font-size: calc(2px + 2vmin);
	}	
}

@media screen and (min-width: 421px) {
    .welcome {
		background-image: url(/static/media/doublebed.36e03b68.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.overview {
		background-image: url(/static/media/sinkiatthani.4e027df6.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.room {
		background-image: url(/static/media/doublebed2.b347f9a4.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.attraction {
		background-image: url(/static/media/twinbed.41906e21.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.booknow {
		background-image: url(/static/media/twinbed.41906e21.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.gallery {
		background-image: url(/static/media/doublebed3.ddf8c79f.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}	
	.contact {
		background-image: url(/static/media/twinbed2.d4dbb403.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
	.booking {
		background-image: url(/static/media/twinbed3.47d0fb8a.jpg);
		background-size: 100vw;
		background-repeat: no-repeat;
		height: 95vh;
		font-size: calc(5px + 2vmin);
	}
}


.DateRangePickerInput {
	border-radius: 7px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  font-size: calc(10px + 2vmin);
  z-index: 100;
}

.modal-main {
  position:fixed;
  background-color: rgba(255,255,255,0.8);
  width: 80%;
  height: auto;
  max-height: 80%;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  border-radius: 15px;
  padding: 15px;
  overflow-y: scroll;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  font-size: calc(10px + 2vmin);
  z-index: 100;
}

.modal-main {
  position:fixed;
  background-color: rgba(255,255,255,0.8);
  width: 80%;
  height: auto;
  max-height: 80%;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  border-radius: 15px;
  padding: 15px;
  overflow-y: scroll;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}
