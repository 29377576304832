@import url('https://fonts.googleapis.com/css?family=Kanit&display=swap');
@import url('https://www.w3schools.com/w3css/4/w3.css');

.App {
  text-align: center;
  font-family: 'Kanit';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-size: 100vw;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: yellow;
}

.footer {
  font-size: calc(0px + 2vmin);
  height: 5vh;
  background-color: black;
  color: white;
  vertical-align: middle;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
animation: fadeIn ease 1.8s;
-webkit-animation: fadeIn ease 1.8s;
-moz-animation: fadeIn ease 1.8s;
-o-animation: fadeIn ease 1.8s;
-ms-animation: fadeIn ease 1.8s;
}
@keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}

@-moz-keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}

@-webkit-keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}

@-o-keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}

@-ms-keyframes fadeIn {
0% {
      opacity: 0.8;
   }
   100% {
      opacity: 1;
   }
}